import React from "react";
import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import theme from "theme";
import { discover } from "services/integrations";
import { showNotification } from "services/notifications";

export interface BusinessEntitiesEditWelcomeProps{
    onCreateEntity: ()=>void
}

const BusinessEntitiesEditWelcomeComponent: React.FC<BusinessEntitiesEditWelcomeProps> = ({onCreateEntity}) => {
    const cards = [
        {
            title: "Run Discovery",
            description: "Lynk will extract metadata from your database schemas and SQL to automatically create entities.",
            icon: "science",
            onClick: async ()=>{
                await discover()
                showNotification({
                    level: "success",
                    title: "Discover statrted",
                    message: "Discovery process might take a few hours. You will receive an email when its done.",
                    autoHideDuration: 5000
                })
            }
        },
        {
            title: "Create an entity",
            description: "Manually create your first entity using a simple Lynk Studio wizard.",
            icon: "add_circle",
            onClick: onCreateEntity
        }
    ]

    return (
        <Box className="flex-box-col-center" sx={{width: "100%", gap: "30px"}}>
                <Typography variant="h4" sx={{textAlign: "center"}}>
                    Let’s create the first entity!
                </Typography>
            
                <Box className="flex-box-center" sx={{gap: "50px", width:"100%"}}>
                    {cards.map((card, index) => (<Card
                        key={index}
                        draggable={false}
                        sx={{
                            border: 0,
                            boxShadow: "none",
                            backgroundColor: "transparent",
                        }}
                    >
                        <CardActionArea
                            onClick={() => card.onClick()}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                boxSizing: "border-box",
                                height: "270px",
                                maxWidth: "250px",
                                borderRadius: "16px",
                                border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                backgroundColor: theme.palette.customColor.white,
                                boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                padding: "32px",
                            }}
                        >
                            <CardContent
                                className="flex-box-col-between"
                                sx={{ height: "100%", padding: 0 }}
                            >
                                <Box className="flex-box-col-center">
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            fontSize: "32px",
                                            color: theme.palette.customColor.purple,
                                        }}
                                    >
                                        {card.icon}
                                    </span>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="center"
                                        sx={{
                                            color: theme.palette.customColor.black,
                                            margin: "24px 0 10px 0",
                                        }}
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="center"
                                        sx={{
                                            fontWeight: 400,
                                            color: theme.palette.customColor.grey,
                                        }}
                                    >
                                        {card.description}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>))}
                </Box>
            </Box>
    );
};

export default BusinessEntitiesEditWelcomeComponent;