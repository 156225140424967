import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../shared/button/button.component";
import { Divider } from "@mui/material";
import theme from "theme";
import BusinessEntityAssetFeature from "../business-entity-features-types/business-entity-asset-feature-component";
import BusinessEntityFormula from "../business-entity-features-types/business-entity-formula-component";
import {FeatureRow} from "../business-entity-tabs/business-entity-tabs.component";
import {FeatureType} from "../../../types/features";

interface BusinessEntityDrawerProps {
    onClose: () => void;
    features: FeatureRow[];
    selectedFeature: FeatureRow | null;
}

const BusinessEntityDrawer: React.FC<BusinessEntityDrawerProps> = ({ onClose, features, selectedFeature }) => {

    /**
     * For some reason passing Boolean(selectedFeature) causes a re-renderinng of the Drawer
     * Causing the animation to fail:
     * https://github.com/mui/material-ui/issues/10587#issuecomment-1557705860 
     */
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => setIsOpen(Boolean(selectedFeature)), [selectedFeature]);

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            onClose={onClose}
            BackdropProps={{ invisible: true }}
            sx={{
                border: '1px solid theme.palette.customColor.lightGrey1',
                boxShadow: '0px 4px 20px 0px rgba(97, 97, 97, 0.13)',
            }}
        >
            <Box className="flex-box-col-between" sx={{ boxSizing: "border-box", width: "525px", height: "100%", padding: "32px 40px 40px 40px" }}>
                { selectedFeature && (
                    <Box sx={{marginBottom: "50px"}}>
                        <Box className="flex-box-center-space-between">
                            <ButtonComponent variant="text"
                                            label={<span className="material-symbols-outlined" style={{ fontSize: "24px" }}>double_arrow</span>}
                                            onClick={onClose} disableRipple sx={{ minWidth: "auto", padding: 0 }} />
                        </Box>

                        <Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.customColor.black, margin: "24px 0 32px 0" }}>
                            {selectedFeature.featureName}
                        </Typography>

                        <Box sx={{ height: "100%" }}>
                            {selectedFeature.featureType === FeatureType.formula ? (
                                <BusinessEntityFormula features={features} formulaFeature={selectedFeature} />
                            ) : (
                                <BusinessEntityAssetFeature assetFeature={selectedFeature}/>
                            )}
                        </Box>
                    </Box>
                )}

                <Box>
                    <Divider sx={{ width: "100%", backgroundColor: theme.palette.customColor.lightGrey1 }} />
                </Box>
            </Box>
        </Drawer>
    );
};

export default BusinessEntityDrawer;